@import url(~antd/dist/antd.min.css);
@import url(~react-phone-input-2/lib/style.css);
@font-face {
  font-family: 'WeblySleekUILight';
  src: local("WeblySleekUILight"), url("../fonts/weblysleekuil.ttf") format("truetype"); }

@font-face {
  font-family: 'WeblySleekUISemilight';
  src: local("WeblySleekUISemilight"), url("../fonts/weblysleekuisl.ttf") format("truetype"); }

@font-face {
  font-family: 'WeblySleekUISemibold';
  src: local("WeblySleekUISemibold"), url("../fonts/weblysleekuisb.ttf") format("truetype"); }

* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

input,
textarea,
select,
button {
  user-select: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input:required, input:invalid,
  textarea:required,
  textarea:invalid,
  select:required,
  select:invalid,
  button:required,
  button:invalid {
    box-shadow: none; }
  input[type="number"],
  textarea[type="number"],
  select[type="number"],
  button[type="number"] {
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial; }
    input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button,
    textarea[type="number"]::-webkit-inner-spin-button,
    textarea[type="number"]::-webkit-outer-spin-button,
    select[type="number"]::-webkit-inner-spin-button,
    select[type="number"]::-webkit-outer-spin-button,
    button[type="number"]::-webkit-inner-spin-button,
    button[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  input::-ms-clear,
  textarea::-ms-clear,
  select::-ms-clear,
  button::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  input::-ms-reveal,
  textarea::-ms-reveal,
  select::-ms-reveal,
  button::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active,
  button:-webkit-autofill,
  button:-webkit-autofill:hover,
  button:-webkit-autofill:focus,
  button:-webkit-autofill:active {
    box-shadow: inset 0 0 0 30px #ffffff !important;
    -webkit-box-shadow: inset 0 0 0 30px #ffffff !important;
    -moz-box-shadow: inset 0 0 0 30px #ffffff !important; }

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

html,
body {
  font-family: "WeblySleekUISemilight";
  height: 100%;
  min-width: 1280px; }

#spacepandamath-dashboard {
  height: 100%; }

a.ant-btn {
  line-height: 1.6; }

header,
.global-sidebar {
  position: fixed; }

.separator-text {
  margin: 20px 0 10px;
  font-size: 18px;
  font-family: "WeblySleekUISemilight";
  color: #191970;
  letter-spacing: 0.02em; }

.site-wrapper {
  padding: 82px 0 52px 230px;
  transition: padding 0.3s ease-in-out;
  -webkit-transition: padding 0.3s ease-in-out;
  -moz-transition: padding 0.3s ease-in-out; }
  .site-wrapper .main-box {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 20px 20px;
    z-index: 1; }
    .site-wrapper .main-box .ant-breadcrumb {
      top: 0px; }

.hidden {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.text-transform-uppercase {
  text-transform: uppercase; }

.text-decoration-underline {
  text-decoration: underline; }

.cursor-pointer {
  cursor: pointer; }

.fa-arrow-left path, .fa-arrow-right path {
  fill: #EF4B6F; }

.flex {
  display: flex; }

.display-inline-block {
  display: inline-block; }

.loading-box {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex; }
  .loading-box.fixed {
    position: fixed; }
  .loading-box .ant-spin {
    display: block; }
    .loading-box .ant-spin .ant-spin-dot-item {
      background-color: #EF4B6F; }

.field-row.half {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .field-row.half.operation {
    justify-content: flex-start; }
    .field-row.half.operation .ant-row {
      width: fit-content; }
      .field-row.half.operation .ant-row:not(:last-child) {
        margin-right: 20px; }
  .field-row.half .field-item {
    width: 48%; }
    .field-row.half .field-item input.hidden {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden; }
      .field-row.half .field-item input.hidden:checked ~ .ant-row {
        display: none; }
  .field-row.half .field-value + .ant-row {
    width: 100%; }
  .field-row.half .ant-row {
    width: 48%; }
    .field-row.half .ant-row .ant-row {
      width: 100%; }
      .field-row.half .ant-row .ant-row .ant-row {
        margin: 0; }
  .field-row.half .ant-space {
    width: 100%; }
    .field-row.half .ant-space-item {
      width: 100%; }
      .field-row.half .ant-space-item:nth-child(2n) {
        width: 15px;
        margin-top: 8px; }
    .field-row.half .ant-space .ant-row {
      width: 100%; }

.field-row.upload .ant-space {
  display: block; }

.field-row .avatar-label {
  cursor: pointer;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  background-color: #fafafa;
  overflow: hidden;
  border: 1px dashed #d9d9d9; }
  .field-row .avatar-label.disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
    opacity: .4; }
  .field-row .avatar-label img {
    display: block;
    width: 100%;
    height: auto; }

.field-row .ant-row {
  display: block; }
  .field-row .ant-row.padding-right {
    padding-right: 100px; }
    .field-row .ant-row.padding-right ~ .ant-input-clear-icon {
      right: 110px; }
  .field-row .ant-row .city-price {
    position: absolute;
    font-family: "WeblySleekUISemilight";
    letter-spacing: 0.01em;
    font-size: 14px;
    color: #171058;
    right: 0;
    top: 40px; }
  .field-row .ant-row .react-tel-input .form-control {
    width: 100%; }
    .field-row .ant-row .react-tel-input .form-control:disabled {
      background-color: #f5f5f5; }

.field-row .ant-picker-clear .anticon-close-circle {
  position: relative;
  top: initial;
  right: initial; }

.field-row .ant-form-item-label, .field-row .ant-form-item-control {
  text-align: left;
  display: block; }

.field-row .ant-form-item-label > label {
  font-family: "WeblySleekUISemibold";
  color: #171058; }
  .field-row .ant-form-item-label > label:after {
    display: none; }

.pos-relative {
  position: relative; }

a.ant-typography {
  color: #171058; }
  a.ant-typography:focus, a.ant-typography:hover {
    color: rgba(23, 16, 88, 0.8); }

.autocomplete-list-box {
  position: absolute;
  font-size: 14px;
  font-family: "WeblySleekUISemilight";
  z-index: 99999;
  background: #ffffff;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4); }

.autocomplete-item {
  cursor: pointer;
  padding: 0 20px;
  height: 38px;
  line-height: 38px; }

@media only screen and (max-width: 1480px) {
  .site-wrapper {
    padding: 82px 0 52px 80px; } }

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background-color: #6254e0; }

.ant-input {
  height: 38px;
  font-size: 14px;
  font-family: "WeblySleekUISemilight";
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  transition: none 0.3s ease-in-out;
  -webkit-transition: none 0.3s ease-in-out;
  -moz-transition: none 0.3s ease-in-out; }
  .ant-input[disabled] {
    opacity: .4; }
  .ant-input-affix-wrapper {
    font-size: 14px;
    font-family: "WeblySleekUISemilight";
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px; }
    .ant-input-affix-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper:focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper:hover {
      border-color: #171058; }
    .ant-input-affix-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper input {
      height: auto; }
  .ant-input-group-wrapper {
    font-size: 14px;
    font-family: "WeblySleekUISemilight";
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px; }
    .ant-input-group-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper:focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper:hover {
      border-color: #171058; }
    .ant-input-group-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper input {
      height: 100%; }
  .ant-input-group {
    height: 38px; }
  .ant-input:not([disabled]):hover {
    border-color: #171058; }
    .ant-input:not([disabled]):hover ~ .flag-dropdown {
      border-color: #171058; }
  .ant-input:not([disabled]):focus {
    border-color: #171058;
    box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1);
    -webkit-box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1);
    -moz-box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1); }
    .ant-input:not([disabled]):focus ~ .flag-dropdown {
      border-color: #171058; }
  .ant-input:not([disabled])-focused {
    border-color: #171058;
    box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1);
    -webkit-box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1);
    -moz-box-shadow: 0 0 0 2px rgba(23, 16, 88, 0.1); }
    .ant-input:not([disabled])-focused ~ .flag-dropdown {
      border-color: #171058; }
  .ant-input-search-button {
    height: 38px;
    width: 38px; }
    .ant-input-search-button svg {
      width: 14px;
      height: 14px; }
  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #171058; }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:focus {
      border-color: #171058; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:hover {
      border-color: #171058; }
  .ant-input-number {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px; }
    .ant-input-number-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):hover {
      border-color: #171058; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number-input {
      height: 36px;
      font-size: 14px;
      font-family: "WeblySleekUISemilight"; }
    .ant-input-number-disabled {
      opacity: .4; }

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #00DD99; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #00DD99; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00DD99;
  border-color: #00DD99; }

.ant-checkbox-checked:after {
  border-color: #00DD99; }

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00DD99; }

.ant-picker {
  width: 100%;
  height: 38px;
  font-size: 14px;
  font-family: "WeblySleekUISemilight";
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px; }
  .ant-picker-today-btn {
    color: #00DD99; }
  .ant-picker-range .ant-picker-active-bar {
    background: #00DD99; }
  .ant-picker-header > button:hover {
    color: #00DD99; }
  .ant-picker-header-view button:hover {
    color: #00DD99; }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #00DD99; }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid #00DD99; }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #00DD99; }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-start-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-start-single):before {
    background-color: #ddfff5; }
  .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background-color: #ddfff5; }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before {
    background-color: #aaffe5 !important; }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before, .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before {
    background-color: #aaffe5 !important; }
  .ant-picker.ant-picker-disabled {
    opacity: .4; }
  .ant-picker.ant-picker-focused {
    border-color: #00DD99;
    box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -moz-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1); }
  .ant-picker:not(.ant-picker-disabled):hover {
    border-color: #00DD99; }
  .ant-picker:not(.ant-picker-disabled):focus {
    border-color: #00DD99;
    box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -moz-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1); }
  .ant-picker:not(.ant-picker-disabled)-focused {
    border-color: #00DD99;
    box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1);
    -moz-box-shadow: 0 0 0 2px rgba(0, 221, 153, 0.1); }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #171058; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(25, 25, 112, 0.2); }

.ant-select-item-option-content span {
  font-weight: 600; }

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #171058;
  box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -webkit-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -moz-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selection-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
  line-height: 38px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px; }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%; }

.ant-select-selection-item span {
  font-weight: 600; }

.ant-btn {
  letter-spacing: 0.01em; }
  .ant-btn-gray {
    color: #000;
    border: 1px solid #000; }
    .ant-btn-gray:hover, .ant-btn-gray:focus, .ant-btn-gray:active {
      border: 1px solid rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5); }
  .ant-btn-approve {
    color: #00DD99;
    border: 1px solid #00DD99; }
    .ant-btn-approve:hover, .ant-btn-approve:focus, .ant-btn-approve:active {
      border: 1px solid rgba(0, 221, 153, 0.5);
      color: rgba(0, 221, 153, 0.5); }
  .ant-btn-reject {
    color: #ea0101;
    border: 1px solid #ea0101; }
    .ant-btn-reject:hover, .ant-btn-reject:focus, .ant-btn-reject:active {
      border: 1px solid rgba(234, 1, 1, 0.5);
      color: rgba(234, 1, 1, 0.5); }
  .ant-btn-discard {
    font-family: "WeblySleekUISemibold";
    min-width: 90px;
    color: rgba(0, 0, 0, 0.4); }
    .ant-btn-discard:hover, .ant-btn-discard:focus, .ant-btn-discard:active {
      border: 1px solid #d9d9d9;
      color: #d9d9d9;
      opacity: .8; }
  .ant-btn-primary {
    background: #EF4B6F;
    border-color: #EF4B6F;
    font-family: "WeblySleekUISemibold";
    min-width: 90px; }
    .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active {
      background: rgba(239, 75, 111, 0.75);
      border-color: rgba(239, 75, 111, 0.75); }

.ant-table-cell.action-wrap {
  text-align: center; }

.ant-table-cell .action-box:not(.custom-button) {
  display: flex;
  justify-content: center; }
  .ant-table-cell .action-box:not(.custom-button) > div:not(:first-child),
  .ant-table-cell .action-box:not(.custom-button) > button:not(:first-child) {
    margin-left: 5px; }

.ant-table-cell .action-box.custom-button > button, .ant-table-cell .action-box.custom-button a {
  min-width: 68px;
  padding: 0 7px 1px;
  height: auto;
  font-family: "WeblySleekUILight";
  font-size: 14px;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  border-radius: 6px;
  letter-spacing: 0.01em; }
  .ant-table-cell .action-box.custom-button > button:not(:first-child), .ant-table-cell .action-box.custom-button a:not(:first-child) {
    margin-left: 6px; }

.ant-table-cell .status-box span {
  font-size: 10px;
  margin: 0;
  display: flex; }
  .ant-table-cell .status-box span.customer, .ant-table-cell .status-box span.captain {
    display: flex;
    align-items: center; }
    .ant-table-cell .status-box span.customer span:first-child, .ant-table-cell .status-box span.captain span:first-child {
      font-family: "WeblySleekUISemilight";
      color: #000;
      margin: 0 5px 0 0; }

.ant-table .icon-edit, .ant-table .icon-info {
  color: #171058;
  cursor: pointer;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=1);
  /* Netscape */
  -moz-opacity: 1;
  /* Safari 1.x */
  -khtml-opacity: 1;
  /* Good browsers */
  opacity: 1; }
  .ant-table .icon-edit:hover, .ant-table .icon-info:hover {
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.8);
    /* Netscape */
    -moz-opacity: 0.8;
    /* Safari 1.x */
    -khtml-opacity: 0.8;
    /* Good browsers */
    opacity: 0.8; }

.ant-table-thead > tr > th {
  padding: 10px 16px;
  color: #00000059; }

.ant-tabs-tab:hover, .ant-tabs-tab:focus, .ant-tabs-tab:active {
  color: #171058; }

.ant-tabs-tab-btn:hover, .ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active {
  color: #171058; }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal;
  color: #171058; }

.ant-tabs-ink-bar {
  background: #171058; }

.ant-switch-checked {
  background: #EF4B6F; }

.ant-breadcrumb {
  position: absolute;
  top: 0;
  left: 20px; }
  .ant-breadcrumb svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px 0 0; }
    .ant-breadcrumb svg path {
      transition: fill 0.3s ease-in-out;
      -webkit-transition: fill 0.3s ease-in-out;
      -moz-transition: fill 0.3s ease-in-out; }
  .ant-breadcrumb span {
    letter-spacing: 0.01em;
    display: inline-block;
    vertical-align: middle; }
    .ant-breadcrumb span.ant-breadcrumb-link {
      font-family: "WeblySleekUISemibold";
      color: #171058; }
      .ant-breadcrumb span.ant-breadcrumb-link svg path {
        fill: #171058; }
  .ant-breadcrumb a svg path {
    fill: rgba(0, 0, 0, 0.45); }
  .ant-breadcrumb a:hover {
    color: #171058; }
    .ant-breadcrumb a:hover svg path {
      fill: #171058; }

.ant-popover-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; }
  .ant-popover-buttons button {
    min-width: 70px;
    margin: 0; }
    .ant-popover-buttons button:first-child {
      margin-left: 15px; }

.ant-form-item textarea.ant-input {
  height: 150px;
  resize: none; }

.ant-rate {
  color: #ffc007; }
  .ant-rate-star:not(:last-child) {
    margin-right: 3px; }
  .ant-rate-star svg {
    width: 14px;
    height: 14px; }
  .ant-rate-star-first, .ant-rate-star-second {
    color: #e6e6e6; }

.ant-tag {
  background: transparent;
  padding: 0;
  border: 0;
  font-family: "WeblySleekUISemibold";
  font-size: 10px;
  min-width: 70px;
  letter-spacing: 0.07em; }
  .ant-tag-label {
    margin-right: 6px;
    color: #00DD99;
    display: inline-block;
    vertical-align: middle; }
  .ant-tag-waiting_for_captain {
    color: #bfbfbf; }
  .ant-tag-waiting_for_price {
    color: #bfbfbf; }
  .ant-tag-waiting_for_shipping {
    color: #bfbfbf; }
    .ant-tag-waiting_for_shipping_days {
      color: #bfbfbf; }
  .ant-tag-pending_payment {
    color: #96a3b3; }
  .ant-tag-shipped {
    color: #171058; }
  .ant-tag-delivered, .ant-tag-rated {
    color: #389e0d; }
  .ant-tag-cancel_requested {
    color: #af5555; }
  .ant-tag-canceled {
    color: #ea0101; }

.ant-typography-delete {
  color: #ea0101;
  cursor: pointer; }
  .ant-typography-delete:focus, .ant-typography-delete:hover {
    color: rgba(234, 1, 1, 0.8); }

.ant-typography-edit, .ant-typography-copy {
  color: #171058; }
  .ant-typography-edit:focus, .ant-typography-edit:hover, .ant-typography-copy:focus, .ant-typography-copy:hover {
    color: rgba(23, 16, 88, 0.8); }

.ant-typography.data-text {
  padding-left: 20px;
  position: relative; }
  .ant-typography.data-text:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    color: #00DD99; }

.ant-typography a {
  color: #171058; }
  .ant-typography a:focus, .ant-typography a:hover {
    color: rgba(23, 16, 88, 0.8); }

.ant-pagination-simple .ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link, .ant-pagination-simple .ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  color: #00DD99; }

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #00DD99; }

.ant-drawer-body {
  padding: 0; }

.ant-drawer .header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .ant-drawer .header .title {
    font-size: 18px;
    color: #171058;
    font-family: "WeblySleekUISemibold";
    letter-spacing: 0.01em; }
  .ant-drawer .header .ant-btn {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px; }

.ant-drawer .content {
  overflow: auto; }
  .ant-drawer .content-wrap {
    padding: 20px; }
  .ant-drawer .content .item {
    padding: 0 0 20px; }
    .ant-drawer .content .item:not(:last-child) {
      margin: 0 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .ant-drawer .content .item-header {
      display: flex;
      justify-content: space-between;
      font-size: 13px; }
      .ant-drawer .content .item-header .title {
        font-family: "WeblySleekUISemibold";
        color: #171058;
        letter-spacing: 0.01em; }
      .ant-drawer .content .item-header .action-box span:not(:last-child) {
        margin-right: 10px; }
    .ant-drawer .content .item-description {
      margin: 12px 0 0;
      font-size: 14px;
      line-height: 20px;
      color: #171058;
      letter-spacing: 0.01em; }
    .ant-drawer .content .item-photos {
      margin: 20px 0 0; }

.ant-drawer-notification .header {
  align-items: center; }
  .ant-drawer-notification .header .title {
    position: relative; }
  .ant-drawer-notification .header .notification-count {
    display: inline-block;
    background-color: #ea0101;
    text-align: center;
    min-width: 17px;
    height: 17px;
    font-size: 12px;
    font-family: "Arial", serif;
    color: #ffffff;
    padding: 0 4px;
    box-sizing: border-box;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px; }

.ant-drawer-notification .content-wrap {
  padding: 0; }

.ant-drawer-notification .content .item {
  padding: 10px 15px 10px 10px;
  margin: 0 !important;
  cursor: pointer;
  transition: all .1s ease-in-out; }
  .ant-drawer-notification .content .item:not(.unread) {
    font-family: "WeblySleekUISemilight";
    opacity: .6; }
  .ant-drawer-notification .content .item:hover {
    opacity: 1; }
  .ant-drawer-notification .content .item-header {
    display: block;
    font-size: 15px; }
    .ant-drawer-notification .content .item-header .title {
      font-family: "WeblySleekUISemilight"; }
  .ant-drawer-notification .content .item.unread {
    background-color: #f3f3f3; }
    .ant-drawer-notification .content .item.unread .item-header .title {
      font-family: "WeblySleekUISemibold"; }
    .ant-drawer-notification .content .item.unread:hover {
      background-color: rgba(243, 243, 243, 0.7); }
  .ant-drawer-notification .content .item-time {
    font-size: 12px;
    color: #737479;
    margin: 4px 0 0; }
  .ant-drawer-notification .content .item-description {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    font-family: "WeblySleekUISemilight"; }

.ant-notification-notice {
  border-radius: 10px;
  padding: 10px 15px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ant-notification-notice-close {
    position: relative;
    top: initial;
    right: initial; }
  .ant-notification-notice-description {
    display: none; }
  .ant-notification-notice.offline .icon-wifi path {
    fill: #bcc0c4; }
  .ant-notification-notice.online .icon-wifi path {
    fill: #25a200; }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center; }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin: 0 0 0 20px;
      padding: 0;
      font-size: 14px;
      letter-spacing: 0.01em; }
  .ant-notification-notice-icon {
    line-height: initial;
    height: 35px;
    position: relative;
    margin: 0; }
    .ant-notification-notice-icon svg {
      width: 35px;
      height: 35px; }

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #00DD99; }

.ant-radio-group {
  line-height: 22px; }

.ant-radio-wrapper {
  font-weight: 600;
  font-size: 15px;
  color: #8E8EA0; }
  .ant-radio-wrapper-checked {
    color: #EF4B6F; }

.ant-radio-inner {
  border: 1px solid #8E8EA0; }

.ant-radio-checked .ant-radio-inner {
  border-color: #EF4B6F; }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #EF4B6F; }

.tool-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;
  padding: 0 0 16px;
  border-bottom: 1px solid #f7f7f7; }
  .tool-box .title {
    font-size: 16px;
    line-height: 1; }
  .tool-box .filter-box {
    min-height: 38px;
    display: flex;
    align-items: center; }
    .tool-box .filter-box .field-box {
      max-width: 260px; }
      .tool-box .filter-box .field-box:not(:last-child) {
        margin: 0 20px 0 0; }
    .tool-box .filter-box .ant-form-item {
      margin: 0; }
    .tool-box .filter-box .ant-input-search {
      width: 220px; }
    .tool-box .filter-box .ant-picker-range {
      width: 100%; }
    .tool-box .filter-box .ant-select {
      width: 200px; }
  .tool-box .total-box .price {
    font-size: 26px;
    line-height: 1.2;
    color: #00DD99;
    letter-spacing: 0.01em; }
  .tool-box .rating-box .wrap-box {
    display: flex;
    align-items: center; }
  .tool-box .rating-box .ant-rate-star:not(:last-child) {
    margin-right: 5px; }
  .tool-box .rating-box .ant-rate-star svg {
    width: 20px;
    height: 20px; }
  .tool-box .rating-box .text {
    margin-left: 20px;
    padding: 3px 0 0;
    color: #171058;
    font-size: 18px;
    font-family: "WeblySleekUISemibold";
    letter-spacing: 0.01em; }

.global-sidebar {
  width: 230px;
  background-color: #f9f9f9;
  height: calc(100vh - 52px);
  top: 52px;
  left: 0; }
  .global-sidebar .sidebar-wrap {
    padding: 30px 0;
    user-select: none; }
  .global-sidebar .ant-menu span {
    color: #171058;
    font-size: 14px;
    font-family: "WeblySleekUISemibold";
    letter-spacing: 0.01em; }
    .global-sidebar .ant-menu span.icon {
      margin-right: 3px;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center; }
      .global-sidebar .ant-menu span.icon svg path {
        fill: #171058; }
    .global-sidebar .ant-menu span.label {
      line-height: 1; }
  .global-sidebar .ant-menu a {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #171058;
    font-size: 14px;
    letter-spacing: 0.01em; }
    .global-sidebar .ant-menu a.active {
      background-color: #f1f1f1; }
  .global-sidebar .ant-menu-submenu-open {
    background-color: #f5f5f5; }
    .global-sidebar .ant-menu-submenu-open ul {
      background-color: #f5f5f5; }
      .global-sidebar .ant-menu-submenu-open ul li a {
        padding: 0 20px; }
  .global-sidebar .ant-menu-submenu-title {
    padding: 4px 10px !important;
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    margin: 0;
    cursor: pointer; }
    @media (hover: hover) {
      .global-sidebar .ant-menu-submenu-title:hover {
        background-color: #efecec !important; }
        .global-sidebar .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .global-sidebar .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); } }
  .global-sidebar .ant-menu-submenu:hover .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #171058; }
  .global-sidebar .ant-menu-submenu-selected {
    background-color: #f1f1f1; }
  .global-sidebar .ant-menu-item-selected {
    background-color: #f1f1f1; }
    .global-sidebar .ant-menu-item-selected:after {
      background-color: transparent;
      display: none; }
  .global-sidebar .ant-menu-item-only-child {
    margin: 0 !important;
    padding: 0 !important; }
    .global-sidebar .ant-menu-item-only-child.ant-menu-item-selected {
      background-color: #f1f1f1; }
      .global-sidebar .ant-menu-item-only-child.ant-menu-item-selected a {
        background-color: transparent; }
    .global-sidebar .ant-menu-item-only-child a {
      padding: 5px 10px; }
  .global-sidebar ul {
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100% !important;
    background-color: transparent; }
    @media (hover: hover) {
      .global-sidebar ul li a:not(.active):hover {
        background-color: #efecec; } }

.ant-menu-item:hover a {
  color: #171058; }

.ant-menu-item a:hover {
  color: #171058; }

.ant-menu-submenu-popup {
  background-color: #f5f5f5; }
  .ant-menu-submenu-popup .ant-menu {
    background-color: #f5f5f5; }
    .ant-menu-submenu-popup .ant-menu-item {
      padding: 0;
      margin: 0 !important; }
      .ant-menu-submenu-popup .ant-menu-item:hover a {
        background-color: #f1f1f1; }
      .ant-menu-submenu-popup .ant-menu-item-selected {
        background-color: transparent !important;
        color: #000; }
        .ant-menu-submenu-popup .ant-menu-item-selected a {
          color: #000; }
      .ant-menu-submenu-popup .ant-menu-item a {
        display: block;
        padding: 0 16px; }
        .ant-menu-submenu-popup .ant-menu-item a.active {
          background-color: #efecec;
          color: #171058; }

@media only screen and (max-width: 1480px) {
  .global-sidebar {
    width: 80px;
    z-index: 9;
    overflow: hidden; }
    .global-sidebar .ant-menu li[role="menuitem"]:not(:last-child) {
      margin-bottom: 5px !important; }
    .global-sidebar .ant-menu span {
      font-size: 11px; }
      .global-sidebar .ant-menu span.icon {
        margin-right: 0;
        width: 100%; }
        .global-sidebar .ant-menu span.icon svg {
          width: initial;
          height: 60%; }
      .global-sidebar .ant-menu span.label {
        width: 100%;
        text-align: center; }
    .global-sidebar .ant-menu-item-only-child {
      height: 60px;
      line-height: inherit; }
      .global-sidebar .ant-menu-item-only-child a {
        flex-wrap: wrap;
        height: 100%; }
    .global-sidebar .ant-menu-submenu > .ant-menu-submenu-title {
      height: 60px;
      line-height: inherit;
      flex-wrap: wrap; }
    .global-sidebar .ant-menu-submenu-arrow {
      display: none; }
    .global-sidebar .ant-menu-submenu-open .ant-menu-inline {
      display: none; } }

header {
  background-color: #171058;
  padding: 10px 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4); }
  header .header-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 60px);
    margin: 0 auto; }
  header .logo svg {
    display: block;
    width: auto;
    height: 30px; }
  header .right {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  header .user-box {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    header .user-box .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      font-family: "WeblySleekUISemibold";
      color: #171058;
      font-size: 14px;
      line-height: 14px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%; }
    header .user-box .user-name {
      margin: 0 10px;
      font-size: 14px;
      font-family: "WeblySleekUISemilight";
      line-height: 1;
      color: #ffffff; }
  header .logout-box button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 3px 0 3px 8px;
    cursor: pointer;
    border-left: 1px solid #ffffff;
    font-family: "WeblySleekUISemibold";
    line-height: 1;
    color: #ffffff;
    font-size: 14px; }

.page-login {
  background-color: #171058; }
  .page-login .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .page-login .logo {
    margin-bottom: 50px; }
    .page-login .logo svg {
      display: block;
      width: auto;
      height: 45px;
      margin: 0 auto; }
  .page-login form {
    box-sizing: border-box;
    width: 350px;
    position: relative;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px; }
    .page-login form .response-box {
      position: absolute;
      top: calc(100% - 85px);
      color: #ea0101;
      width: 100%;
      text-align: center;
      font-size: 14px; }
    .page-login form .ant-input {
      background-color: #f7f7f7;
      height: 42px;
      padding: 4px 11px;
      font-size: 14px;
      border: 1px solid #f7f7f7;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px; }
    .page-login form .ant-form-item {
      margin-bottom: 25px; }
      .page-login form .ant-form-item-label {
        display: none; }
      .page-login form .ant-form-item-explain {
        font-size: 12px;
        min-height: auto;
        position: absolute;
        bottom: -18px;
        left: 6px; }
      .page-login form .ant-form-item-has-error input.ant-input {
        border-color: #ea0101; }
      .page-login form .ant-form-item-with-help {
        margin-bottom: 25px; }
      .page-login form .ant-form-item:last-child {
        margin-bottom: 0; }
      .page-login form .ant-form-item.password-filed-box {
        margin-bottom: 65px; }
    .page-login form .ant-btn {
      margin: 0 auto;
      padding: 0;
      height: 42px;
      font-family: "WeblySleekUISemibold";
      color: #ffffff;
      background-color: #EF4B6F;
      border: 0;
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px; }

.page-settings-languages .language-list {
  display: flex;
  flex-wrap: wrap; }

.page-settings-languages .language-item {
  width: 22.75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
  margin: 0 3% 0 0;
  border-bottom: 1px solid #f7f7f7; }
  .page-settings-languages .language-item:nth-child(4n) {
    margin: 0; }
  .page-settings-languages .language-item:not(:nth-of-type(-n + 4)) {
    padding: 15px 0; }

.page-settings-payment-methods .payment-list {
  display: flex;
  flex-wrap: wrap; }

.page-settings-payment-methods .payment-item {
  width: 22.75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
  margin: 0 3% 0 0;
  border-bottom: 1px solid #f7f7f7; }
  .page-settings-payment-methods .payment-item:nth-child(4n) {
    margin: 0; }
  .page-settings-payment-methods .payment-item:not(:nth-of-type(-n + 4)) {
    padding: 15px 0; }

.page-settings-shipping-methods .shipping-list {
  display: flex;
  flex-wrap: wrap; }

.page-settings-shipping-methods .shipping-item {
  width: 22.75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
  margin: 0 3% 0 0;
  border-bottom: 1px solid #f7f7f7; }
  .page-settings-shipping-methods .shipping-item:nth-child(4n) {
    margin: 0; }
  .page-settings-shipping-methods .shipping-item:not(:nth-of-type(-n + 4)) {
    padding: 15px 0; }

.page-center.view-edit .payment-box .content-wrapper {
  max-width: 660px;
  display: block; }

.page-center.view-edit .payment-box h2 {
  font-size: 24px;
  font-weight: 400;
  color: #191970;
  text-align: center;
  margin: 0 0 40px; }

.page-center.view-edit .payment-box .plan-info {
  width: 100%; }
  .page-center.view-edit .payment-box .plan-info .description,
  .page-center.view-edit .payment-box .plan-info .text {
    letter-spacing: 0.02em;
    color: #191970;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400; }
  .page-center.view-edit .payment-box .plan-info .text {
    margin: 15px 0 0; }
    .page-center.view-edit .payment-box .plan-info .text:before {
      content: '* ';
      color: #EF4B6F; }

.page-center.view-edit .payment-box .rc-tabs-tab ~ .rc-tabs-ink-bar {
  margin: 0; }

.page-center.view-edit .payment-box .rc-tabs {
  margin: 40px 0 0; }
  .page-center.view-edit .payment-box .rc-tabs-ink-bar {
    display: none; }
  .page-center.view-edit .payment-box .rc-tabs-nav-operations {
    display: none !important; }
  .page-center.view-edit .payment-box .rc-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: center; }
  .page-center.view-edit .payment-box .rc-tabs-nav-wrap {
    margin: 0 0 50px; }
  .page-center.view-edit .payment-box .rc-tabs-tab {
    width: 152px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    color: #191970;
    margin: 0 17px 0 0;
    cursor: pointer;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.3);
    /* Netscape */
    -moz-opacity: 0.3;
    /* Safari 1.x */
    -khtml-opacity: 0.3;
    /* Good browsers */
    opacity: 0.3;
    box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    -webkit-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    -moz-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    @media (hover: hover) {
      .page-center.view-edit .payment-box .rc-tabs-tab:hover {
        opacity: 1; } }
    .page-center.view-edit .payment-box .rc-tabs-tab:nth-last-child(-n+2) {
      margin: 0; }
    .page-center.view-edit .payment-box .rc-tabs-tab-active {
      opacity: 1; }
    .page-center.view-edit .payment-box .rc-tabs-tab > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 5px; }
      .page-center.view-edit .payment-box .rc-tabs-tab > div img {
        margin: 0 15px 0 0;
        display: block;
        height: auto;
        width: 44px; }
      .page-center.view-edit .payment-box .rc-tabs-tab > div span {
        width: fit-content;
        line-height: 19px; }
  .page-center.view-edit .payment-box .rc-tabs-content-holder {
    max-width: 660px;
    margin: 0 auto;
    width: 96%; }

.page-center.view-edit .payment-box form .text-info {
  position: absolute;
  left: 0;
  bottom: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #191970;
  letter-spacing: 0.02em; }

.page-center.view-edit .payment-box form .separator {
  display: flex;
  align-items: flex-start;
  margin: 65px 0; }
  .page-center.view-edit .payment-box form .separator span {
    display: block; }
    .page-center.view-edit .payment-box form .separator span.text {
      line-height: 16px;
      font-size: 18px;
      font-weight: 400;
      color: #191970;
      padding: 0 0 6px;
      position: relative;
      border-bottom: 1px solid transparent;
      transition: border-bottom 0.3s ease-in-out;
      -webkit-transition: border-bottom 0.3s ease-in-out;
      -moz-transition: border-bottom 0.3s ease-in-out; }
      .page-center.view-edit .payment-box form .separator span.text:after {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 11px;
        bottom: -20px; }
    .page-center.view-edit .payment-box form .separator span.icon {
      margin: 0 10px 0 0; }
  .page-center.view-edit .payment-box form .separator div {
    display: flex;
    align-items: flex-start;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.6);
    /* Netscape */
    -moz-opacity: 0.6;
    /* Safari 1.x */
    -khtml-opacity: 0.6;
    /* Good browsers */
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out; }
    .page-center.view-edit .payment-box form .separator div:not(:last-child) {
      margin: 0 15px 0 0; }
    .page-center.view-edit .payment-box form .separator div.active {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1; }
      .page-center.view-edit .payment-box form .separator div.active span.text {
        border-bottom: 1px solid #191970; }
        .page-center.view-edit .payment-box form .separator div.active span.text:after {
          content: attr(data-text); }

.page-center.view-edit .payment-box .btn {
  margin: 20px auto 0; }

.page-results.view-create .student-box, .page-results.view-edit .student-box {
  width: 100%;
  display: flex;
  height: 38px;
  font-size: 14px;
  font-family: "WeblySleekUISemilight";
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  transition: none 0.3s ease-in-out;
  -webkit-transition: none 0.3s ease-in-out;
  -moz-transition: none 0.3s ease-in-out;
  border: 1px solid #d9d9d9;
  justify-content: space-between; }
  .page-results.view-create .student-box:hover, .page-results.view-edit .student-box:hover {
    border-color: #171058; }
  .page-results.view-create .student-box .user-info, .page-results.view-edit .student-box .user-info {
    padding: 0 12px;
    line-height: 36px; }
  .page-results.view-create .student-box .action > button, .page-results.view-edit .student-box .action > button {
    margin: 8px 12px 0 0;
    font-size: 12px;
    min-width: 50px;
    padding: 0 7px 1px;
    height: auto;
    font-family: "WeblySleekUISemibold";
    box-shadow: none;
    text-shadow: none;
    border: 0;
    background-color: #a8a9b9;
    color: #ffffff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    letter-spacing: 0.01em; }
  .page-results.view-create .student-box .assign, .page-results.view-edit .student-box .assign {
    box-sizing: border-box;
    font-family: "WeblySleekUISemilight";
    padding: 4px 11px;
    cursor: pointer;
    height: 100%;
    width: 100%;
    line-height: 28px; }
